<template>
  <!-- Footer area start -->
  <footer >
    <div class="footer__copyright white-bg">
      <div class="container">
        <div class="copyright__inner">
          <div class="copyright__logo">
            <a href="/"><img src="../assets/vslogo1.svg" alt="logo not found" width="140"></a>
          </div>
          <div class="copyright__text">
            <p>Copyright &copy; 2023-2025 VSVISA.COM. <a href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备2023019731号-1</a></p>
          </div>
          <div class="copyright__logo">
            <Dropdown style="margin-left: 20px; width: 200px">
              <Button >
                快速跳转<Icon type="ios-arrow-down"></Icon>
              </Button>
              <template #list>
                <DropdownMenu>
                  <DropdownItem><router-link to="/" >首页</router-link></DropdownItem>
                  <DropdownItem><router-link to="/jipd" >免费机票单</router-link></DropdownItem>
                  <DropdownItem><a href="https://main.vsvisa.com" target="_blank">申根机酒行助手</a></DropdownItem>
                </DropdownMenu>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer area end -->
</template>
<script>

export default {
  name: 'publicFoot',

}
</script>

<style scoped>
footer {
  padding-top: 40px;
}

</style>
